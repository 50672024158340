<template>
  <section>
    <div class="relative overflow-hidden bg-white">
      <!-- Upper Hero Section -->
      <div>
        <div class="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32 pt-20">
          <svg
            class="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-white lg:block"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true">
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <main class="mx-auto max-w-7xl px-6 lg:px-8 lg:py-12">
            <div class="sm:text-center lg:text-left">
              <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                <span class="block">Seattle's Premier Karting Track</span>
                <span class="block text-blue-600">Racing Rain or Shine!</span>
              </h1>
            </div>
          </main>
        </div>
      </div>
      <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <nuxt-img
          class="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
          provider="cloudinary"
          sizes="xs:380px sm:800px md:1280px"
          densities="x1 x2"
          alt="Racing Karts on track"
          :src="homepage.queryForImageByKey(homepage, 'home_page_hero_one')" />
      </div>
    </div>

    <div class="bg-white">
      <div class="py-24 px-6 sm:px-6 sm:py-32 lg:px-8">
        <div
          class="mx-auto max-w-2xl text-center">
          <h2 class="text-4xl font-bold tracking-tight text-gray-900">
            {{ homepage.section_one_title }}
          </h2>
          <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            {{ homepage.section_one_subtitle }}
          </p>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/racing"
              class="rounded-md bg-blue-600 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Learn more</a>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white">
      <div class="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
        <div class="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1024 1024"
            class="absolute top-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:translate-y-0 lg:-translate-x-1/2"
            aria-hidden="true">
            <circle
              cx="512"
              cy="512"
              r="512"
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fill-opacity="0.7"></circle>
            <defs>
              <radialGradient
                id="759c1415-0410-454c-8f7c-9a820de03641"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(512 512) rotate(90) scale(512)">
                <stop stop-color="#7775D6"></stop>
                <stop
                  offset="1"
                  stop-color="#E935C1"
                  stop-opacity="0"></stop>
              </radialGradient>
            </defs>
          </svg>
          <div class="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {{ homepage.section_two_title }}
            </h2>
            <p class="mt-6 text-lg leading-8 text-gray-300">
              {{ homepage.section_two_subtitle }}
            </p>
            <div class="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <a
                href="/racing#arrive-and-drive"
                class="rounded-md bg-white px-3.5 py-1.5 text-base font-semibold leading-7 text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >Get started</a>
            </div>
          </div>
          <div class="relative mt-16 h-80 lg:mt-8">
            <nuxt-img
              class="absolute top-0 left-0 w-[57rem] lg:max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
              alt="Racing karts on track"
              provider="cloudinary"
              sizes="xs:380px sm:800px md:1280px"
              densities="x1 x2"
              :src="homepage.queryForImageByKey(homepage, 'home_page_hero_two')"></nuxt-img>
          </div>
        </div>
      </div>
    </div>

    <racing-options
      :title="homepage.racing_options_title"
      :racing-options="[
        {
          name: homepage.racing_options_one_title,
          description: homepage.racing_options_one_subtitle,
          icon: homepage.racing_options_one_icon,
          to: getURL(homepage.racing_options_one_title)
        },
        {
          name: homepage.racing_options_two_title,
          description: homepage.racing_options_two_subtitle,
          icon: homepage.racing_options_two_icon,
          to: getURL(homepage.racing_options_two_title)
        },
        {
          name: homepage.racing_options_three_title,
          description: homepage.racing_options_three_subtitle,
          icon: homepage.racing_options_three_icon,
          to: getURL(homepage.racing_options_three_title)
        },
      ]"></racing-options>

    <current-schedule
      :title="homepage.schedule_title"
      :subtitle="homepage.schedule_subtitle"
      :schedule-image="homepage.queryForImageByKey(homepage, 'home_page_schedule')"></current-schedule>

    <ten-fastest></ten-fastest>
  </section>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { homepageBaseline } from '~/constants'
useHead({
      titleTemplate: 'PacificGP | Home'
})
const config = useRuntimeConfig()
let homepage = Object.assign({}, homepageBaseline)
// Load Page Content
const { data, error } = await useAsyncData('homepage', () => $fetch(`${config.public.apiBase}/api/home-page?populate=*`, {
  method: 'GET',
  headers: {
    Authorization: `Bearer ${config.public.apiReadOnlyToken}`
  }
}))

if (data.value) {
  homepage = {
    ...homepage,
    ...data.value.data.attributes
  }
} else {
  useBugsnag().notify(error.value)
}

function getURL (page) {
  page = page.toLowerCase()
  switch (page) {
    case 'league racing':
      return '/racing#rental-league-racing'
    case 'endurance racing': 
      return '/racing#endurance-rental-racing'
    case 'group events': 
      return '/events'
    default:
      return ''
  }
}
</script>