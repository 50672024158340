<template>
  <div class="mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
    <div v-if="fastest.length">
      <h2 class="text-sm font-medium text-gray-500">
        This weeks fastest
      </h2>
      <ul
        role="list"
        class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
        <li
          v-for="(lapper, index) in fastest"
          :key="lapper.racer_id"
          class="col-span-1 flex rounded-md shadow-sm">
          <div class="bg-blue-600 italic flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-lg font-medium text-white">
            {{ index + 1 }}
          </div>
          <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
            <div class="flex-1 truncate px-4 py-2 text-sm">
              <span
                class="font-medium text-gray-900 hover:text-gray-600">{{ lapper.lap_time }}</span>
              <p class="text-gray-500">
                {{ lapper.first_name }} {{ lapper.last_name }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
const config = useRuntimeConfig()
let fastest = []
// Load Page Content
const { data, error } = await useAsyncData('laptimes', () => $fetch(`${config.public.apiBase}/api/laptimes`, {
  method: 'GET'
}))

if (data.value) {
  fastest = data.value.fastest
} else {
  useBugsnag().notify(error.value)
}
</script>