<template>
  <div class="overflow-hidden py-16 lg:py-24">
    <div class="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
      <svg
        class="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
        aria-hidden="true">
        <defs>
          <pattern
            id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse">
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor" />
          </pattern>
        </defs>
        <rect
          width="404"
          height="784"
          fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
      </svg>

      <div class="relative">
        <h2 class="text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          {{ title }}
        </h2>
        <p class="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-500">
          {{ subtitle }}
        </p>
      </div>

      <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-12">
        <section class="mt-12">
          <h2 class="font-semibold text-gray-900">
            Upcoming events
          </h2>
          <ol class="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
            <li
              v-for="item in calendar.items"
              :key="item.id"
              class="py-4 sm:flex">
              <time
                :datetime="parseDate(item.start.dateTime ? item.start.dateTime : item.start.date, 'yyyy-MM-dd')"
                class="w-28 flex-none">{{ parseDate(item.start.dateTime ? item.start.dateTime : item.start.date) }}</time>
              <!-- // Check for arrive and drive events - create link to that page -->
              <p
                v-if="item.summary.indexOf('Arrive and Drive Karting') !== 0"
                class="mt-2 flex-auto font-semibold text-gray-900 sm:mt-0">
                {{ item.summary }}
              </p>
              <a
                v-else
                class="mt-2 flex-auto font-semibold text-gray-900 sm:mt-0 block"
                href="#">
                {{ item.summary }}</a>
              <!-- // Time Based Events -->
              <p
                v-if="item.start.dateTime"
                class="flex-none sm:ml-6">
                <time datetime="2022-01-13T14:30">{{ parseDate(item.start.dateTime, 'hh:mm a') }}</time> -
                <time datetime="2022-01-13T16:30">{{ parseDate(item.end.dateTime, 'hh:mm a') }}</time>
              </p>
              <!-- // All Day Events -->
              <p
                v-else
                class="flex-none sm:ml-6">
                All day
              </p>
            </li>
            <li class="py-4 sm:flex">
              <span class="w-28 flex-none"></span>
              <nuxt-link
                to="calendar"
                class="mt-2 flex-auto font-semibold text-gray-900 sm:mt-0 text-right">
                View Full Calendar
              </nuxt-link>
            </li>
          </ol>
        </section>

        <div
          class="relative -mx-4 mt-10 lg:mt-0 overflow-hidden rounded-lg"
          aria-hidden="true">
          <svg
            class="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
            width="784"
            height="404"
            fill="none"
            viewBox="0 0 784 404">
            <defs>
              <pattern
                id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse">
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor" />
              </pattern>
            </defs>
            <rect
              width="784"
              height="404"
              fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
          </svg>
          <nuxt-img
            class="relative mx-auto"
            provider="cloudinary"
            sizes="xs:380px md:620px"
            densities="x1 x2"
            :src="scheduleImage"
            alt="racing schedule"></nuxt-img>
        </div>
      </div>

      <svg
        class="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
        aria-hidden="true">
        <defs>
          <pattern
            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse">
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor" />
          </pattern>
        </defs>
        <rect
          width="404"
          height="784"
          fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
      </svg>
    </div>
  </div>
</template>

<script setup>
import {format, parseISO} from 'date-fns'
const config = useRuntimeConfig()
// Assign Props
defineProps({
  scheduleImage: {
    type: String,
    default: 'placeholder_udwi8w.png'
  },
  title: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  }
})
let calendar = null
// Load Page Content
const { data, error } = await useAsyncData('calendar', () => $fetch(`${config.public.apiBase}/api/google-calendar?fields=short_calendar`, {
  method: 'GET',
  headers: {
    Authorization: `Bearer ${config.public.apiReadOnlyToken}`
  }
}))

if (data.value) {
  calendar = data.value.data.attributes.short_calendar
} else {
  useBugsnag().notify(error.value)
}

function parseDate (date, dateFormat = 'EEE, MMM d') {
  date = parseISO(date)
  return format(date, dateFormat)
}
</script>